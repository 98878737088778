<template>
    
        <v-virtual-scroll
            v-if="visibleItems.length > 0"
            :items="visibleItems"
            :item-height="40"
            :height="windowHeight >= 937 ? '400' : '200'"
            :bench="10"
            class="virt-scrl-list-tree"
        >
            <template v-slot:default="{ item, index }">
                
                <v-list-item
                    :key="index"
                    @click="onItemClick(item)"
                    :style="{ marginLeft: (item.level - 1) * 25 + 'px' }"
                    v-ripple="{ class: item.active || item.hasChildren ? `primary--text` : `red--text` }"
                >
                    <v-list-item-action
                        :class="[item.level == 1 ? 'group-item-action' : '', item.hasChildren && item.level > 1 ? 'item-with-arrow' : '']"
                    >

                        <v-icon
                            v-if="item.hasChildren"
                            small
                        >
                            {{ item.collapsed ? 'fas fa-caret-right' : 'fas fa-caret-down' }}
                        </v-icon>

                        <!--<div v-else class="fake-arrow-dropdown"></div>-->

                        <v-checkbox
                            v-if="!item.hasChildren && item.active"
                            color="primary"
                            hide-details
                            class="p-0"
                            :input-value="isItemSelected(item)"
                            @click.native.capture="onItemSelect(item, $event)"
                        >
                        </v-checkbox>
                        <v-checkbox
                            v-else-if="allowParentSelection && item.active && item.children.filter(x => x.active).length"
                            color="primary"
                            hide-details
                            class="p-0 allow-parent-selection-checkbox"
                            :input-value="isParentItemSelected(item)"
                            @click.native.capture="onParentItemSelect(item, $event)"
                        >
                        </v-checkbox>
                        <div v-else-if="(item.level > 1 && !item.hasChildren) || allowParentSelection" class="fake-checkbox"></div>
                        <!--<div v-else-if="item.level > 1 && !item.hasChildren" class="fake-checkbox"></div>
                        <div v-else-if="item.level > 1 && item.hasChildren" class="fake-checkbox-has-child"></div>-->
                                                    

                    </v-list-item-action>

                    <v-list-item-icon
                        v-if="item.level > 1"
                    >
                        <v-icon 
                            v-if="item.position == 0"
                            color="red darken-2"
                            v-tooltip.top-center='$t("Руководитeль_организации")'
                            small
                        >
                            mdi-account-tie
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 1"
                            color="red darken-2"
                            v-tooltip.top-center='$t("Заместитель_руководителя_организации")'
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 2"
                            color="green darken-2"
                            v-tooltip.top-center='$t("Руководитель_подразделения")'
                            small
                        >
                            mdi-account-tie
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 3"
                            color="green darken-2"
                            v-tooltip.top-center='$t("Заместитель_руководителя_подразделения")'
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'Department'"
                            v-tooltip.top-center='$t("Подразделение")'
                            small
                        >
                            mdi-account-group
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'WorkPlace' || item.element_type == 'Person'"
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'Enterprise' || item.element_type == 'Contractor'"
                            small
                        >
                            fas fa-landmark
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_category == 'ErrorMessage' && item.element_type == 'Empty'"
                            small
                        >
                            fas fa-exclamation-circle
                        </v-icon>

                    </v-list-item-icon>

                    <v-list-item-content v-if="item.hasChildren">

                        <v-list-item-title>
                            <!--<span
                                :class="[item.level > 1 ? 'font-weight-medium' : 'font-weight-bold', 'text-uppercase']"
                            >
                                {{ item.level > 1 ? item.name : $t(item.name) }}
                            </span>-->

                            <span
                                :class="['font-weight-bold', 'text-uppercase']"
                                v-if="item.level<=1"
                            >
                                {{ $t(item.name) }}
                            </span>
                            <span
                                v-else
                                :class="['font-weight-medium', 'text-uppercase']"
                                v-html="highlight(item.name)"
                            >
                            </span>
                        </v-list-item-title>

                    </v-list-item-content>
                    <v-list-item-content v-else>
                        <v-list-item-title>
                            <div class="d-flex" >

                                <!-- Наименование -->
                                <div
                                    class="d-flex align-center flex-grow-1"
                                >
                                    <div v-if="item.element_category == 'ErrorMessage'" class="etw-text-container">
                                        <span class="etw-text text-uppercase">
                                            {{ $t(item.name) }}
                                        </span>
                                    </div>
                                    <div v-else class="etw-text-container" v-tooltip.top-center='item.name ? item.name : ""'>
                                        <span class="etw-text text-uppercase" v-html="highlight(item.name)"></span>
                                    </div>
                                </div>

                                <!-- Должность -->
                                <div
                                    class="d-flex align-center flex-grow-0"
                                    style="width: 27.5%;"
                                    v-if="item.element_category != 'OuterESEDO' && item.element_category != 'OuterContractors'"
                                >
                                    <div class="etw-text-container" v-tooltip.top-center='createTooltip(item)'>
                                        <span class="etw-text">
                                            {{ item.jobtitle }}
                                        </span>
                                    </div>
                                </div>
                                
                                <!-- Контакты -->
                                <div
                                    class="d-flex align-center flex-grow-0 vslt-contact-col"
                                    style="width: 27.5%;"
                                    v-if="item.element_category != 'OuterESEDO' && item.element_category != 'OuterContractors'"
                                >
                                    <template v-if="item.contacts">
                                        <v-contact-item
                                            v-for="contact in item.contacts"
                                            :key="contact.id"
                                            :type="contact.type === 'email' ? 'email' : 'phone'"
                                            :title="contact.value"
                                            :description="contact.description"
                                            :hide-load="true"
                                            :is-read-only="true"
                                        />
                                    </template>
                                </div>

                                <!-- Замешающий -->
                                <div
                                    class="d-flex align-center flex-grow-0"
                                    style="width: 20%;"
                                    v-if="item.element_category != 'OuterESEDO' && item.element_category != 'OuterContractors'"
                                >
                                    <div v-if="item.subid" class="onlyReadData more-per-lab-wrap">
                                        <v-workplace-chip
                                            :id="item.subid"
                                            :name="item.subname"
                                            v-tooltip.top-center='$t("Замещение")'
                                        />
                                    </div>
                                </div>

                            </div>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                
            </template>

        </v-virtual-scroll>
        <div
            v-else
            class="div-table-row"
        >
            <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
        </div>
    
</template>

<script>
import sys from '@/services/system';

export default {
    name: "VirtualScrollTree",
    props: {
        items: {
            type: Array,
            default: () => []
        },
        itemsType: {
            type: String,
            default: ""
        },
        selectedIds: {
            type: Array,
            default: () => []
        },
        inactiveIds: {
            type: Array,
            default: () => []
        },
        allowParentSelection: {
            type: Boolean,
            default: false
        },
        expandAll: {
            type: Boolean,
            default: false
        },
        filterString: {
            type: String,
            default: ""
        }
    },
    computed: {
        initItems() {
            const flatten = (items, level = 1, parent = null) => items.reduce(
                (acc, v) => {

                    v.level = level;
                    v.parent = parent;
                    v.visible = v.parent != null ? !v.parent.collapsed : true;
                    v.active = !this.inactiveIds.includes(v.id) && !this.inactiveIds.includes(v.subid) && v.allow && this.isHierarchicallyAllowed(v);
                    v.hasChildren = !!(Array.isArray(v.children) && v.children.length);
                    v.filtered = false;

                    acc.push(v);

                    if (v.hasChildren) {
                        v.collapsed = this.expandAll ? false : v.level > 1 ? true : false;
                        acc.push(...flatten(v.children, level + 1, v));
                    }

                    return acc;
                }, []);

            // console.warn('INIT_ITEMS_CALL!');

            return flatten(JSON.parse(JSON.stringify(this.items)), 1);
        },
        visibleItems() {

            if (!this.filterString || this.filterString.length < 3)
                return this.internalList.filter(x => x.visible);

            return this.internalList.filter(x => x.visible && x.filtered);
        },
        windowHeight() {
            return window.innerHeight;
        },
        isHierarchical() {
            return this.$store.getters['dialogs/selectMembersNew/isHierarchical'];
        },
    },
    data() {
        return {
            internalList: [],
        }
    },
    methods: {
        onItemClick(item) {
            const changeItemsVisibility = (item, visible, state) => {
                    
                item.visible = item.parent ? !item.parent.collapsed && visible : visible;

                if (item.hasChildren) {

                    var i = 0, len = item.children.length;
                    // итерируем список
                    while (i < len) {
                        //отмечаем итерируемый элемент как выбранный
                        changeItemsVisibility(item.children[i], !state, state);
                        i++;
                    }
                }
            };

            if (!item) return;

            let internalListItem = this.internalList.find(x => x.id == item.id);

            if (!internalListItem) return;
            
            if (!item.hasChildren) {
                this.onItemSelect(item);
                return;
            }

            internalListItem.collapsed = !internalListItem.collapsed;
            changeItemsVisibility(internalListItem, true, internalListItem.collapsed);
        },
        onItemSelect(item, event) {

            if (!item) return;

            let internalListItem = this.internalList.find(x => x.id == item.id);

            if (!internalListItem || !internalListItem.active) return;

            this.$emit('on-select', { select: !this.selectedIds.includes(internalListItem.id), value: internalListItem });
            
            if (event)
                event.stopPropagation();
        },
        isItemSelected(item) {
            return this.selectedIds.includes(item.id)
        },
        isParentItemSelected(item) {
            let actives = item.children.filter(x => x.active);

            if (!actives.length)
                return false;

            return actives.every(x => this.selectedIds.includes(x.id));
        },
        onParentItemSelect(item, event) {

            this.$emit('on-select', { select: !this.isParentItemSelected(item), value: item });

            if (event)
                event.stopPropagation();
        },
        applyFilterString() {

            let isEmptySearch = !this.filterString || this.filterString.length < 3;
            var i = 0, len = this.internalList.length;

            while (i < len) {

                if (isEmptySearch) {

                    this.internalList[i].filtered = false;
                    this.internalList[i].visible = this.internalList[i].parent != null ? !this.internalList[i].parent.collapsed : true;

                    if (this.internalList[i].hasChildren)
                        this.internalList[i].collapsed = this.expandAll ? false : this.internalList[i].level > 1 ? true : false;

                }
                else {
                    this.internalList[i].filtered = this.internalList[i].parent && ((this.internalList[i].parent.level>1 && sys.checkSearch(this.internalList[i].parent.name, this.filterString)) || sys.checkSearch(this.internalList[i].name, this.filterString));

                    /*if (!this.internalList[i].hasChildren)
                        this.internalList[i].filtered = sys.checkSearch(this.internalList[i].name, this.filterString);
                    else
                        this.internalList[i].filtered = false;*/

                    this.internalList[i].visible = this.internalList[i].filtered;

                    if (this.internalList[i].filtered) {

                        let tmp = this.internalList[i].parent;

                        while (tmp != null) {
                            tmp.filtered = true;
                            tmp.visible = true;
                            tmp.collapsed = false;
                            tmp = tmp.parent;
                        }
                    }

                }

                i++;
            }
        },
        highlight(content) {
            if (!this.filterString)
                return content;

            let filter = this.filterString.trim();

            if (!filter)
                return content;

            let searchTokens = filter.split(' ');
            let fullsearchsuccess = false;
            let rez = String(content).replace(new RegExp(filter, "gi"), match => {
                fullsearchsuccess = true;
                return `<span style="background:#C5E1A5">${match}</span>`;
            });

            if (!fullsearchsuccess && searchTokens.length > 1) {
                searchTokens.forEach(element => {
                    if (element) {
                        rez = String(rez).replace(new RegExp(element, "gi"), match => {
                            return `<span style="background:#C5E1A5">${match}</span>`;
                        });
                    }
                });
            }

            return rez;
        },
        createTooltip(item) {
            if (this.itemsType === "ExecuterGroups"){
                let resultStr = '';
                resultStr += item.enterprisename ? item.enterprisename : "";

                resultStr += item.jobtitle 
                    ? item.enterprisename 
                        ? "/" + item.jobtitle 
                        : item.jobtitle
                    : "";
                return resultStr;    
            }
            return item.jobtitle ?? '';
            
        },
        isHierarchicallyAllowed(item) {
            if (!this.isHierarchical || item.element_category !== "InnerExecuter")
                return true;

            let currentUserPosition = this.$store.getters['auth/getUserPositionFlag'];
            /*
            -1: Рядовой сотрудник
             0: Руководитeль организации
             1: Заместитель руководителя организации
             2: Руководитель подразделения
             3: Заместитель руководителя подразделения
             4: Канцеляр
             5: Особые полномочия
            */
            switch (currentUserPosition)
            {
                case 5:
                    return [100, 2, 3, 5].includes(item.position);

                case 100:
                case 2:
                case 3:
                    return [100, 2, 3].includes(item.position);

                case 0:
                case 1:
                case 4:
                    return true;

                default:
                    return false;
            }
        }
    },
    watch: {
        items: {
            handler(v) {
                console.log("handler", v);
                this.internalList = this.initItems;
                this.applyFilterString();
            },
            deep: true
        },
        filterString() {
            this.applyFilterString();
        }
    },
    mounted () {
        // console.log("mounted", this._uid);
        this.internalList = this.initItems;
        this.applyFilterString();
    },
}
</script>